/** @jsx jsx */
// import { jsx } from "theme-ui"
// import * as React from "react"
// import { PageProps } from "gatsby"
// import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image"
// import Homepage from '@lekoarts/gatsby-theme-jodie/src/components/homepage'

// const HomepageCustom: React.FC<PageProps<DataProps>> = ({ data: { pages, projects } }) => {
//   const rawItems = [...pages.nodes, ...projects.nodes]
//   const items = modifyGrid(rawItems)
//   const itemsCount = items.length
//   let divisor = 9
//
//   for (let i = 0; i < itemsCount; i++) {
//     const quotient = itemsCount % divisor
//     const quotientAlt = (itemsCount - 1) % divisor
//
//     if (quotient === 0 || quotientAlt === 0) {
//       break
//     }
//
//     divisor -= 1
//   }
//
//   return (
//     <Layout>
//       <h1 sx={visuallyHidden} data-testid="page-title">
//         {locales.home}
//       </h1>
//       <div className={`item-list-wrapper`} sx={itemListWrapperStyles}>
//         <div className={`item-list div${divisor}`}>
//           {items.length > 0 ? (
//             items.map((item, index) => (
//               <GridItem to={item.slug} className="item" key={item.title} sx={itemStyles} data-testid={item.title}>
//                 <GatsbyImage
//                   loading={index === 0 ? `eager` : `lazy`}
//                   image={item.cover.childImageSharp.gatsbyImageData}
//                   alt=""
//                 />
//                 <span>{item.title}</span>
//               </GridItem>
//             ))
//           ) : (
//             <div sx={{ padding: 3 }}>
//               No projects and pages found at the locations defined for "projectsPath" and "pagesPath"
//             </div>
//           )}
//         </div>
//       </div>
//     </Layout>
//   )
// }

import { jsx } from "theme-ui"
import * as React from "react"
import Layout from "@lekoarts/gatsby-theme-jodie/src/components/layout"
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../../../photos"
import {useCallback, useState} from "react";

const imgWithClick = { cursor: 'pointer' };

const PhotoCustom = ({ index, onClick, photo, margin, direction, top, left, key }) => {
  const imgStyle: { [key: string]: any } = { margin: margin, display: 'block' };
  if (direction === 'column') {
    imgStyle.position = 'absolute';
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = event => {
    onClick(event, { photo, index });
  };

  if (photo.title) {
    return (
      <div
        key={key}
        style={{ position: 'relative' }}
      >
        {/*This is used for sizing*/}
        <img
          key={key}
          style={{...imgStyle, visibility: 'hidden' }}
          {...photo}
          onClick={onClick ? handleClick : null}
        />
        <div
          key={key}
          style={{ position: 'absolute', width: '100%', top: 0}}
        >
          <div style={{ padding: '48px 32px', textAlign: 'left' }}>
            <h3>{photo.title}</h3>
            {photo.subtitle && (<span>{photo.subtitle}</span>)}
          </div>
        </div>
      </div>
    )
  }

  return (
    <img
      key={key}
      style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
      {...photo}
      onClick={onClick ? handleClick : null}
    />
  );
};

interface ExtraPhotoProps {
  title: string
  subtitle: string
}

// class GalleryWithExtraProps extends Gallery<ExtraPhotoProps> {}

function GalleryContent() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Gallery<ExtraPhotoProps> photos={photos} onClick={openLightbox} renderImage={PhotoCustom} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}




const GalleryScreen = () => (
  <Layout>
    <div style={{ textAlign: `center` }}>
      <GalleryContent />
    </div>
  </Layout>
)

export default GalleryScreen

